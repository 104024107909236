<template>
  <div class="row">
    <retry-notification
      :show="error"
      @retry="updateData()"
    />
    <div class="flex xs12">
      <va-card :title="$t('trainings.roles.edit')">
        <actions
          slot="actions"
          crud-links="roles"
          controller="DcpiRoles"
          :actions="actions"
          :action-data="{id: $route.params.id}"
        />
        <data-form
          :role="role"
          :loading="loading"
          @submit="submit"
        />
      </va-card>
    </div>
  </div>
</template>

<script>
const Actions = () => import(/* webpackPrefetch: true */ '@/components/extras/Actions')
const DataForm = () => import(/* webpackPrefetch: true */ './Form')

export default {
  name: 'roles-edit',
  components: {
    Actions,
    DataForm,
  },
  data () {
    return {
      error: false,
      loading: false,
      actions: ['index', 'new', 'view', 'delete'],
      role: { trainer: false },
    }
  },
  created () {
    this.updateData()
  },
  methods: {
    // FIXME
    routeBuilder (id) {
      return `dcpi-roles/${id}`
    },
    async updateData () {
      this.loading = true
      this.error = false

      let u = false
      const roleId = this.$route.params.id
      try {
        u = await this.$http.get(this.routeBuilder(roleId))
      } catch (err) {
        // console.log('Error fetching role data', err)
        this.error = true
        this.loading = false
        return
      }

      this.loading = false
      this.role = u.data.data
    },
    async submit (role) {
      this.loading = true
      try {
        await this.$http.put(this.routeBuilder(role.id), role)
      } catch (error) {
        // console.log('Error updating data', error)
        this.loading = false
        return
      }

      this.loading = false
      this.showToast(this.$t('notifications.alerts.success'), {
        icon: 'fa-check',
        position: 'top-right',
      })
    },
  },
}
</script>
